import { GraphQLFormattedError } from 'graphql';

export class CustomGraphQLError<E> extends Error {
  errors: (E | GraphQLFormattedError)[];
  constructor(message, errors) {
    super(message);
    this.errors = errors;
  }
}

export function checkErrorExtensionsFor(
  errors: unknown[],
  code: GraphQLErrorEnum,
  data?: { model: GraphQLDataModelsEnum }
): boolean {
  if (errors.length === 0) {
    return false;
  } else if (data && data.model) {
    return errors.some((error: any) => error.extensions.code === code && error.extensions.data.model === data.model);
  } else {
    return false;
  }
}

export enum GraphQLErrorEnum {
  RECORD_NOT_FOUND = 'RECORD_NOT_FOUND'
}

export enum GraphQLDataModelsEnum {
  patient = 'Patient'
}
