import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DocumentNode, FieldNode, Kind, OperationDefinitionNode, print } from 'graphql';
import { Observable, map } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  checkErrorExtensionsFor,
  CustomGraphQLError,
  GraphQLDataModelsEnum,
  GraphQLErrorEnum
} from '../utils/graphql-error';
import { SentryCategory } from '../models/sentry.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  readonly _router: Router = inject(Router);
  readonly _http: HttpClient = inject(HttpClient);
  readonly _snackbar: MatSnackBar = inject(MatSnackBar);

  public multipart<T, V = { [key: string]: any }>({
    variables,
    query,
    file
  }: {
    query: DocumentNode;
    variables?: V;
    file: FormData;
  }): Observable<T> {
    const form = file;
    form.append('query', print(query));
    form.append('variables', JSON.stringify(variables));
    const operationDefinitionNode = query.definitions.find(
      def => def.kind === Kind.OPERATION_DEFINITION
    ) as OperationDefinitionNode;
    const queryNames = operationDefinitionNode.selectionSet.selections.map(
      (selection: FieldNode) => selection.name.value
    );
    Sentry.addBreadcrumb({
      category: SentryCategory.graphQL,
      level: 'info',
      data: { query: queryNames.join(', ') }
    });
    return this._http.post<{ data?: T; errors?: any }>(`/graphql`, form, { headers: { multipart: 'true' } }).pipe(
      map(response => {
        if (response.errors) {
          const error = new Error(JSON.stringify(response.errors));
          this._snackbar.open('Server Error Occurred', null, { duration: 5000, panelClass: 'error-toast' });
          Sentry.captureException(error);
          throw error;
        } else if (queryNames.some(name => response.data[name].errors && response.data[name].errors.length > 0)) {
          const error = new Error(JSON.stringify(queryNames.map(queryName => response.data[queryName].errors)));
          Sentry.captureException(error);
          throw error;
        }
        return queryNames.reduce((accum, queryName) => ({ ...accum, ...response.data[queryName] }), {} as T);
      })
    );
  }

  public request<T, V = { [key: string]: any }>({
    variables,
    query,
    options = {
      headers: {}
    }
  }: {
    query: DocumentNode;
    variables?: V;
    options?: {
      headers: { [key: string]: string };
    };
  }): Observable<T> {
    const operationDefinitionNode = query.definitions.find(
      def => def.kind === Kind.OPERATION_DEFINITION
    ) as OperationDefinitionNode;
    const primaryQueryName = (operationDefinitionNode.selectionSet.selections[0] as FieldNode).name.value;
    Sentry.addBreadcrumb({
      category: SentryCategory.graphQL,
      level: 'info',
      data: { query: primaryQueryName }
    });
    return this._http
      .post<{
        data?: T;
        errors?: unknown[];
      }>(`/graphql`, { query: print(query), variables }, { headers: options.headers })
      .pipe(
        map(response => {
          if (response.errors) {
            const error = new Error(JSON.stringify(response.errors));
            if (
              checkErrorExtensionsFor(response.errors, GraphQLErrorEnum.RECORD_NOT_FOUND, {
                model: GraphQLDataModelsEnum.patient
              })
            ) {
              this._snackbar.open('Record Not Found', null, { duration: 5000, panelClass: 'error-toast' });
              this._router.navigate(['/home/profiles']);
              throw new CustomGraphQLError(`GraphQL Error: ${JSON.stringify(response.errors)}`, response.errors);
            } else {
              this._snackbar.open('Server Error Occurred', null, { duration: 5000, panelClass: 'error-toast' });
            }
            Sentry.captureException(error);
            throw new CustomGraphQLError(
              `GraphQL Error: ${JSON.stringify(response.errors)}`,
              response.data[primaryQueryName].errors
            );
          }
          return response.data[primaryQueryName];
        })
      );
  }

  public mutate<R, P = { [key: string]: any }>({
    variables,
    query,
    options = {
      headers: {}
    }
  }: {
    query: DocumentNode;
    variables?: P;
    options?: {
      headers: { [key: string]: string };
    };
  }): Observable<R> {
    const operationDefinitionNode = query.definitions.find(
      def => def.kind === Kind.OPERATION_DEFINITION
    ) as OperationDefinitionNode;
    const queryName = (operationDefinitionNode.selectionSet.selections[0] as FieldNode).name.value;
    Sentry.addBreadcrumb({
      category: SentryCategory.graphQL,
      level: 'info',
      data: { query: queryName }
    });
    return this._http
      .post<{ data?: R; errors?: any }>(
        `/graphql`,
        {
          ...options,
          query: print(query),
          variables
        },
        { headers: options.headers }
      )
      .pipe(
        map(response => {
          if (response.errors && response.errors.length > 0) {
            throw new CustomGraphQLError(`GraphQL Error: ${JSON.stringify(response.errors)}`, response.errors);
          } else if (
            response.data[queryName] &&
            response.data[queryName].errors &&
            response.data[queryName].errors.length > 0
          ) {
            throw new CustomGraphQLError(
              `GraphQL Error: ${JSON.stringify(response.errors)}`,
              response.data[queryName].errors
            );
          } else {
            return response.data[queryName];
          }
        })
      );
  }
}
